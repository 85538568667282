/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/Earth.glb -o src/components/Earth.jsx -k -K -r public 
*/

//Earth by Poly by Google [CC-BY] (https://creativecommons.org/licenses/by/3.0/)
//via Poly Pizza (https://poly.pizza/m/88CP80Kgb-u)

import { React, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";

export function Earth(props) {
  const { nodes, materials } = useGLTF("/models/Earth.glb");
  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.01; // Rotate around Y-axis
      groupRef.current.rotation.x += 0; // Optional: Rotate around X-axis
    }
  });

  return (
    <group scale={(0.2, 0.2, 0.2)} {...props} ref={groupRef} dispose={null}>
      <group>
        <mesh
          name="Icosphere_Icosphere001"
          geometry={nodes.Icosphere_Icosphere001.geometry}
          material={materials["Material.005"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/Earth.glb");
