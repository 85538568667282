/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/Linkedin.glb -o src/components/Linkedin.jsx -k -K -r public 
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function Linkedin(props) {
  const { nodes, materials } = useGLTF("/models/Linkedin.glb");

  const handleClick = () => {
    window.open("https://www.linkedin.com/in/SandhuKaran", "_blank");
  };

  return (
    <group
      {...props}
      scale={[0.43, 0.43, 0.43]}
      dispose={null}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <group name="Scene">
        <group name="Empty001" scale={1.2} />
        <mesh
          name="Square"
          geometry={nodes.Square.geometry}
          material={materials.Linkedin}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          name="Linkedin_Icon"
          geometry={nodes.Linkedin_Icon.geometry}
          material={materials.White}
          position={[-0.55, -0.48, 0.22]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/Linkedin.glb");
