/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/Monitor.glb -o src/components/Monitor.jsx -k -K -r public 
*/

import { React, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

export function Monitor(props) {
  const { nodes, materials } = useGLTF("/models/Monitor.glb");
  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.004; // Rotate around Y-axis
      groupRef.current.rotation.x += 0; // Optional: Rotate around X-axis
    }
  });

  return (
    <group {...props} scale={[0.05, 0.05, 0.05]} ref={groupRef} dispose={null}>
      <group>
        <mesh
          name="iMac"
          geometry={nodes.iMac.geometry}
          material={materials.Mat}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/Monitor.glb");
