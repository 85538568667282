/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/Github.glb -o src/components/Github.jsx -k -K -r public 
*/

//GitHub by IconScout Store on IconScout

import React from "react";
import { useGLTF } from "@react-three/drei";

export function Github(props) {
  const { nodes, materials } = useGLTF("/models/Github.glb");

  const handleClick = () => {
    window.open("https://www.github.com/SandhuKaran", "_blank");
  };

  return (
    <group
      {...props}
      scale={[0.15, 0.15, 0.15]}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      dispose={null}
    >
      <group name="Scene">
        <group name="Empty" />
        <mesh
          name="Round_Cube"
          geometry={nodes.Round_Cube.geometry}
          material={materials.Apple_Voice_Memos}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1.26, 2.735, 2.735]}
        />
        <mesh
          name="Round_Cube004"
          geometry={nodes.Round_Cube004.geometry}
          material={materials.Apple_Voice_Memos}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1.26, 2.735, 2.735]}
        />
        {/* <mesh
          name="Curve011"
          geometry={nodes.Curve011.geometry}
          material={materials["SVGMat.020"]}
          position={[-8.203, -2.553, 3.304]}
          scale={548.644}
        /> */}
        <mesh
          name="Exclude"
          geometry={nodes.Exclude.geometry}
          material={materials["SVGMat.021"]}
          position={[-0.171, -0.122, 1.9]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={298.802}
        />
        <mesh
          name="Github"
          geometry={nodes.Github.geometry}
          material={materials.Github}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1.26, 2.735, 2.735]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/Github.glb");
