/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/Telescope.glb -o src/components/Telescope.jsx -k -K -r public 
*/

import { React, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";

export function Telescope(props) {
  const { nodes, materials } = useGLTF("/models/Telescope.glb");
  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.007; // Rotate around Y-axis
      groupRef.current.rotation.x += 0; // Optional: Rotate around X-axis
    }
  });

  return (
    <group {...props} scale={[2, 2, 2]} ref={groupRef} dispose={null}>
      <group>
        <mesh
          name="group756371822"
          geometry={nodes.group756371822.geometry}
          material={materials.mat17}
        />
        <group name="group64228703">
          <mesh
            name="mesh64228703"
            geometry={nodes.mesh64228703.geometry}
            material={materials.mat17}
          />
          <mesh
            name="mesh64228703_1"
            geometry={nodes.mesh64228703_1.geometry}
            material={materials.mat20}
          />
          <mesh
            name="mesh64228703_2"
            geometry={nodes.mesh64228703_2.geometry}
            material={materials.mat19}
          />
          <mesh
            name="mesh64228703_3"
            geometry={nodes.mesh64228703_3.geometry}
            material={materials.mat18}
          />
          <mesh
            name="mesh64228703_4"
            geometry={nodes.mesh64228703_4.geometry}
            material={materials.mat25}
          />
        </group>
        <group name="group135009730">
          <mesh
            name="mesh135009730"
            geometry={nodes.mesh135009730.geometry}
            material={materials.mat17}
          />
          <mesh
            name="mesh135009730_1"
            geometry={nodes.mesh135009730_1.geometry}
            material={materials.mat16}
          />
          <mesh
            name="mesh135009730_2"
            geometry={nodes.mesh135009730_2.geometry}
            material={materials.mat15}
          />
          <mesh
            name="mesh135009730_3"
            geometry={nodes.mesh135009730_3.geometry}
            material={materials.mat5}
          />
          <mesh
            name="mesh135009730_4"
            geometry={nodes.mesh135009730_4.geometry}
            material={materials.mat21}
          />
          <mesh
            name="mesh135009730_5"
            geometry={nodes.mesh135009730_5.geometry}
            material={materials.mat8}
          />
          <mesh
            name="mesh135009730_6"
            geometry={nodes.mesh135009730_6.geometry}
            material={materials.mat20}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/models/Telescope.glb");
