/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/Simple-suitcase.glb -o src/components/suitcase.jsx -k -K -r public 
*/

// Simple Suitcase by Don Carson [CC-BY] (https://creativecommons.org/licenses/by/3.0/)
// via Poly Pizza (https://poly.pizza/m/023W-XcCmir)

import React, { useRef, useState } from "react";
import { useGLTF, OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";

export function Suitcase(props) {
  const { nodes, materials } = useGLTF("/models/Simple-suitcase.glb");
  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.007; // Rotate around Y-axis
      groupRef.current.rotation.x += 0; // Optional: Rotate around X-axis
    }
  });

  return (
    <group {...props} scale={[5, 5, 5]} ref={groupRef} dispose={null}>
      <group>
        <group name="group881833563">
          <mesh
            name="mesh881833563"
            geometry={nodes.mesh881833563.geometry}
            material={materials.mat20}
          />
          <mesh
            name="mesh881833563_1"
            geometry={nodes.mesh881833563_1.geometry}
            material={materials.mat19}
          />
          <mesh
            name="mesh881833563_2"
            geometry={nodes.mesh881833563_2.geometry}
            material={materials.mat17}
          />
          <mesh
            name="mesh881833563_3"
            geometry={nodes.mesh881833563_3.geometry}
            material={materials.mat22}
          />
          <mesh
            name="mesh881833563_4"
            geometry={nodes.mesh881833563_4.geometry}
            material={materials.mat18}
          />
        </group>
        <group name="group971960435">
          <mesh
            name="mesh971960435"
            geometry={nodes.mesh971960435.geometry}
            material={materials.mat18}
          />
          <mesh
            name="mesh971960435_1"
            geometry={nodes.mesh971960435_1.geometry}
            material={materials.mat22}
          />
          <mesh
            name="mesh971960435_2"
            geometry={nodes.mesh971960435_2.geometry}
            material={materials.mat20}
          />
          <mesh
            name="mesh971960435_3"
            geometry={nodes.mesh971960435_3.geometry}
            material={materials.mat19}
          />
          <mesh
            name="mesh971960435_4"
            geometry={nodes.mesh971960435_4.geometry}
            material={materials.mat17}
          />
          <mesh
            name="mesh971960435_5"
            geometry={nodes.mesh971960435_5.geometry}
            material={materials.mat8}
          />
          <mesh
            name="mesh971960435_6"
            geometry={nodes.mesh971960435_6.geometry}
            material={materials.mat1}
          />
          <mesh
            name="mesh971960435_7"
            geometry={nodes.mesh971960435_7.geometry}
            material={materials.mat5}
          />
          <mesh
            name="mesh971960435_8"
            geometry={nodes.mesh971960435_8.geometry}
            material={materials.mat16}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/models/Simple-suitcase.glb");
